@use '../../styles/utils' as *;

.twoColumnBlock {
  display: flex;
  flex-direction: column;
  padding: rem(40) 0;

  @include breakpoint-md {
    padding: rem(64) 0;
  }

  @include breakpoint-xl {
    padding: rem(80) 0;
  }
}

.header {
  margin-bottom: rem(40);
  text-align: center;

  @include breakpoint-md {
    margin-bottom: rem(40);
  }
}

.columns {
  display: flex;
  flex-direction: column;

  @include breakpoint-md {
    flex-direction: row;
  }
}

.description {
  margin-top: rem(8);
}

.leftColumn {
  flex: 1;
}

.rightColumn {
  flex: 1;
}

.divider {
  background-color: rgba($color-slate, 8%);
  flex: 0 0 auto;
  height: rem(4);
  margin: rem(24) 0;
  width: auto;

  @include breakpoint-md {
    height: auto;
    margin: 0 rem(48);
    width: rem(4);
  }
}

.themed {
  @include themify($themes) {
    background-color: themed('backgroundColorSecondary');
  }
}

.columnHeading {
  margin-bottom: rem(8);
}

.ctaWrapper {
  margin-top: rem(24);
}
