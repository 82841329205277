@use '../../styles/utils' as *;

.sectionContainerWithPadding {
  padding: rem($section-gutter-vertical-mobile) 0;

  @include breakpoint-md {
    padding: rem($section-gutter-vertical-tablet) 0;
  }

  @include breakpoint-lg {
    padding: rem($section-gutter-vertical-desktop) 0;
  }
}

@include color-classes('background-color');
