@use '../../../styles/utils' as *;

.discoverCard {
  height: rem(240);
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 0;

  @include breakpoint-xl {
    height: rem(260);
    width: rem(384);
  }
}

.backgroundMediaWrapper {
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 66%;

  @include breakpoint-md {
    width: 60%;

    img {
      height: 100%;
      left: 0;
      width: 100%;
    }
  }

  @include breakpoint-xl {
    width: 66%;
  }

  img {
    height: 100%;
    object-fit: cover;
    object-position: 0% 50%;
    position: absolute;
    width: 100%;
    z-index: -100;
  }
}

.background {
  height: 100%;
}

.backgroundBlock {
  background-color: $color-lime;
  height: 100%;
  position: absolute;
  right: 0;
  width: calc(34% + 1px);

  @include breakpoint-md {
    width: calc(40% + 1px);
  }

  @include breakpoint-xl {
    width: calc(34% + 1px);
  }

  @include themify($themes) {
    background-color: themed('backgroundColor');
  }
}

.backgroundTriangle {
  fill: $color-lime;
  position: absolute;
  right: 34%;

  @include breakpoint-md {
    right: 40%;
  }

  @include breakpoint-xl {
    right: 34%;
  }

  @include themify($themes) {
    fill: themed('backgroundColor');
  }
}

.headingWrapper {
  bottom: rem(12.68);
  position: absolute;
  right: rem(24);
  text-align: right;
  white-space: normal;
  width: rem(128);

  @include themify($themes) {
    color: themed('textColor');
  }

  @include breakpoint-md {
    width: 50%;
  }

  @include breakpoint-xl {
    width: rem(160);
  }
}
