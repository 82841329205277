@use '../../styles/utils' as *;

/* Default styles, this should be updated */
.genericText {
  @include breakpoint-md {
    display: flex;
    justify-content: center;
    padding-bottom: rem(112);
    text-align: left;
  }

  div {
    @include breakpoint-md {
      max-width: rem(588);
    }

    @include breakpoint-xl {
      max-width: rem(800);
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-block-end: rem(32);
  }

  p {
    margin-block-end: rem(32);
  }
}

.disclaimer {
  background-color: rgba($color-slate, 8%);
  padding: rem(24) 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-block-end: rem(16);
  }

  p {
    @include base-smallest-regular;

    margin-block-end: rem(16);
  }
}
