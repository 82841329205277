@use '../../styles/utils' as *;

.tileContainerBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: rem(40) 0;

  @include breakpoint-md {
    flex-direction: row;
    padding: rem(64) 0;
  }

  @include breakpoint-xl {
    padding: rem(40) 0;
  }

  > div {
    margin-bottom: rem(44);
    margin-right: 0;

    @include breakpoint-md {
      margin-bottom: 0;
      margin-right: rem(24);
      max-width: rem(384);
      width: 33%;
    }

    &:only-child {
      flex-grow: 1;
    }

    &:last-child {
      margin-bottom: 0;
      margin-right: 0;
    }
  }
}

.ctaBlock {
  display: flex;
  justify-content: center;
  margin-bottom: rem(40);

  @include breakpoint-md {
    margin-bottom: rem(64);
  }

  @include breakpoint-xl {
    margin-bottom: rem(80);
  }

  > span:not(:first-child) {
    margin-left: rem(24);
  }
}
