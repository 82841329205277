@use '../../styles/utils' as *;

.container {
  align-items: center;
  display: flex;

  .pageButton {
    align-items: center;
    background-color: none;
    border-radius: rem(32);
    cursor: pointer;
    display: flex;
    height: rem(40);
    justify-content: center;
    margin-right: rem(32);
    text-decoration: underline;

    svg {
      height: rem(16);
    }
  }

  .pageButtonSelected {
    background-color: $color-lime;
    font-weight: 700;
    margin-right: rem(26.5);
    min-width: rem(40);
    text-decoration: none;
    width: rem(40);
  }

  .pageButtonSpecial {
    background-color: none;
    height: rem(16);
    min-width: rem(16);
    text-decoration: none;
    width: rem(16);
  }
}
