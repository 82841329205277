@use '../../../styles/utils/' as *;

.listingContainer {
  align-items: center;
  display: flex;
}

.loadingState,
.emptyState {
  display: flex;
  justify-content: center;
  padding-bottom: rem(40);
  text-align: center;

  @include breakpoint-lg {
    padding: 0 rem(248) rem(80) rem(248);
  }
}

.newsEventsListingGrid {
  display: grid;
  grid-row-gap: rem(40);
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 1fr;
  padding: rem(40) 0 rem(32);

  @include breakpoint-md {
    grid-column-gap: rem(24);
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    padding: rem(64) 0;
  }

  @include breakpoint-lg {
    grid-template-columns: repeat(3, 1fr);
    padding: rem(80) 0;
  }
}

.paginationWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: rem(32);
  width: 100%;

  @include breakpoint-md {
    margin-bottom: rem(64);
  }

  @include breakpoint-lg {
    margin-bottom: rem(80);
  }
}
