@use '../core' as *;

// Sizing
$form-input-height-val: 48;
$form-input-height: rem($form-input-height-val);
$form-input-border-width-val: 2;
$form-input-border-width: rem($form-input-border-width-val);

// Typography
$form-input-font-size: rem(16);
$form-input-font-size-medium: rem(14);
$form-input-font-family: $body-font-family;

// Spacing
$form-input-spacing-y: 24;

// Initial
$form-input-color: $color-slate;
$form-input-background-color: rgba($color-slate, 4%);
$form-input-border-color: $color-slate;

// Hover
$form-input-background-color-hover: rgba($color-slate, 8%);
$form-input-border-color-hover: $color-slate;

// Focus
$form-input-background-color-focus: rgba($color-slate, 4%);
$form-input-border-color-focus: $color-lime;

// Disabled
$form-input-background-color-disabled: rgba($color-slate, 8%);
$form-input-border-color-disabled: $color-slate;

// Error
$form-input-background-color-error: rgba($color-error, 8%);
$form-input-color-error: $color-error;
$form-input-border-color-error: $color-error;

// Input images (base64 encoded)
$form-select-chevron: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAICAYAAADN5B7xAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACZSURBVHgBhY+xDYQwDEXtMAQ6oewQIYFc3gi3wd0mcJtceSNQUlMgsQFFJJiCYCMFuQjiF5Hl/5xvA7BcVTm4UWQyR9QYxH9u7bx6PyVhojczXV4UYAwAStOE8BMjCbN3MMxmi/f9w1oZevLz0kkahhC+4zC0GH8qiVpuNlJviJ+YqmEpUcfroVMKPo7Wnl4vBV9Kksq6ToI7oStFFdj1sWwAAAAASUVORK5CYII=');
$form-select-chevron-error: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAICAYAAADN5B7xAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAB/SURBVHgBhY25DYAwDEXtCIlFoKdBilgGNgE2gWWidDRUhF1MHBEU5RC/8vGeDWBz9X0HPzlfRhgpZ6yq45ZyLMG8qy3DrAAhkIeEuOUknvHONZZ1sBmGBYhmrpFoarTeExhxbZVa0F+KJf81hF0Zvg+lLwGcCIkUwcWw5MRMHtmLPcsQIytiAAAAAElFTkSuQmCC');
$form-checkbox-tick: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAOCAYAAAAi2ky3AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADHSURBVHgBpdExDsIgFAbgR72AWzUNdyBNShk9Qm+gNxFPop7A1dGx6WB0c3SgsacQeSSYaqwB+g8FkvLlfwFgZFhRMFwnMCJMiGVCyDHNMkggMhbReod7gxACEekjoPXm3DQyGPqF4DYIGkKCoH8Ixr4aK8vtjNJpp9Q1BrGNGOeVecIDHp6ErC51vQ9FbKOubW9zSnHEhflUKaV318wXsY3cJhdCmp/XrhmuvsgH9I2944HY0fqHh1InN2YIMhhslnMuQ+68APIDe297beXdAAAAAElFTkSuQmCC');

// Misc
$form-input-disabled-opacity: 0.5;

@mixin form-field-spacing {
  margin-bottom: rem($form-input-spacing-y);
}

@mixin form-field-assitive-text {
  @include font-size(14 20);

  color: rgba($color-slate, 50%);
  padding-top: rem(6);
}

@mixin form-field-error-message {
  @include font-size(14 20);

  color: $color-error;
  padding-top: rem(6);
}

@mixin form-fieldset {
  margin-bottom: rem($form-input-spacing-y);
}

// Label for Text, Textarea
@mixin form-input-label {
  display: block;
  font-weight: 600;
  margin-bottom: rem(8);

  &Error {
    color: $color-error;
  }
}

// Label for Radio, Checkbox
@mixin form-radio-checkbox-label {
  cursor: pointer;
  display: grid;
  font-family: $form-input-font-family;
  font-size: $form-input-font-size;
  gap: rem(12);
  grid-template-columns: rem(24) auto;
  line-height: rem(24);

  // iOS de-styling
  -webkit-tap-highlight-color: transparent;

  &Error {
    color: $color-error;
  }

  a {
    color: $color-blue;

    &:hover {
      text-decoration: underline;
    }
  }

  &:focus-visible {
    border-color: $color-lime;
    outline: none;
  }
}

// Checkbox & Radio Selection
@mixin form-selection-label {
  @include transition-ease-out(background-color border-color);

  align-items: center;
  border: 2px solid $color-slate;
  border-radius: rem(40);
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  font-size: $form-input-font-size-medium;
  height: rem(40);
  justify-content: center;
  margin-bottom: rem(8);
  margin-right: rem(8);
  min-width: rem(61);
  padding: 0 rem(24) 0 rem(26);
  user-select: none;

  &:last-of-type {
    margin: 0;
  }

  &:focus-visible {
    border-color: $color-lime;
    outline: none;
  }
}

// Text, TextArea
@mixin form-input {
  @include transition-ease-out(background-color border-color);

  background-color: $form-input-background-color;
  border: 0 none;
  border-bottom: $form-input-border-width solid $form-input-border-color;
  border-radius: 0;
  box-sizing: border-box;
  color: $form-input-color;
  font-family: $form-input-font-family;
  font-size: $form-input-font-size;
  outline: none;
  padding: rem(12);
  width: 100%;

  // override the default browser autofill background
  &:autofill {
    // note - one off color
    box-shadow: 0 0 0 rem(40) #f5f5f5 inset !important;
  }

  &:required {
    border-color: $form-input-border-color;
  }

  &:disabled {
    background-color: $form-input-background-color-disabled;
    border-color: $form-input-border-color-disabled;
    opacity: $form-input-disabled-opacity;
  }

  &:hover:not(&Error) {
    background-color: $form-input-background-color-hover;
    border-color: $form-input-border-color-hover;
  }

  &:focus:not(&Error, &ReadOnly) {
    background-color: $form-input-background-color-focus;
    border-color: $form-input-border-color-focus;
  }

  &ReadOnly {
    background-color: $form-input-background-color-disabled;
    border-color: $form-input-border-color-disabled;
    opacity: $form-input-disabled-opacity;
  }

  &Error:not(:disabled) {
    background-color: $form-input-background-color-error;
    border-color: $form-input-border-color-error;
    color: $form-input-color-error;

    &::placeholder {
      color: $form-input-color-error;
    }
  }

  &::placeholder {
    color: rgba($color-slate, 50%);
  }

  // autofill behaviour
  &:-webkit-autofill ~ label {
    color: $color-slate;
    transform: scale(0.75) translateY(-#{rem(7)});
  }
}

// Radio
@mixin form-radio {
  @include transition-ease-out(background-color border-color);

  appearance: none;
  background-color: $form-input-background-color;
  border: 2px solid currentcolor;
  border-radius: 50%;
  color: currentcolor;
  display: grid;
  font: inherit;
  height: rem(24);
  margin: 0;
  place-content: center;
  width: rem(24);

  &::before {
    @include transition-ease-out(transform);

    border-radius: 50%;
    box-shadow: inset rem(16) rem(16) $color-lime;
    content: '';
    height: rem(14);
    transform: scale(0);
    width: rem(14);
  }

  &:checked::before {
    transform: scale(1);
  }

  &Error:not(:disabled) {
    background-color: $form-input-background-color-error;
    border-color: $form-input-border-color-error;
    color: $form-input-color-error;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    border-color: $color-lime;
  }

  &:checked {
    background-color: $color-white;
    border: 2px solid $color-lime;
  }

  &:disabled,
  &ReadOnly {
    background-color: $form-input-background-color-disabled;
    border-color: $form-input-border-color-disabled;
    opacity: $form-input-disabled-opacity;
  }

  &:hover:not(&Error, :checked) {
    background-color: $form-input-background-color-hover;
    border-color: $form-input-border-color-hover;
  }
}

// Checkbox
@mixin form-checkbox {
  @include transition-ease-out(background-size background-color border-color);

  appearance: none;
  background-color: $form-input-background-color;
  background-image: $form-checkbox-tick;
  background-position: center;
  background-repeat: no-repeat;
  background-size: rem(0);
  border: 2px solid currentcolor;
  border-radius: 0;
  color: currentcolor;
  height: rem(24);
  margin: 0;
  width: rem(24);

  &Error:not(:disabled) {
    background-color: $form-input-background-color-error;
    border-color: $form-input-border-color-error;
    color: $form-input-color-error;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    border-color: $color-lime;
  }

  &:checked {
    background-color: $color-lime;
    background-size: rem(18);
    border: 2px solid $color-lime;
  }

  &:disabled,
  &ReadOnly {
    background-color: $form-input-background-color-disabled;
    border-color: $form-input-border-color-disabled;
    opacity: $form-input-disabled-opacity;
  }

  &:hover:not(&Error, :checked) {
    background-color: $form-input-background-color-hover;
    border-color: $form-input-border-color-hover;
  }
}

// Checkbox & Radio Selection
@mixin form-selection {
  @include transition-ease-out(background-size);

  // hide input but not for screen readers
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  option {
    color: $form-input-color;
  }

  &:checked {
    + label {
      background-color: $color-lime;
      border-color: $color-lime;
    }
  }

  &:disabled,
  &ReadOnly {
    + label {
      background-color: $form-input-background-color-disabled;
      border-color: $form-input-border-color-disabled;
      opacity: $form-input-disabled-opacity;
    }
  }

  &Error:not(:checked, :disabled) {
    + label {
      border-color: $form-input-border-color-error;
      color: $form-input-color-error;
    }
  }

  &:hover:not(:checked, &Error) {
    + label {
      background-color: $form-input-background-color-hover;
      border-color: $form-input-border-color-hover;
    }
  }

  &:focus-visible {
    + label {
      border-color: $color-lime;
      outline: none;
    }
  }
}

// SelectBox
@mixin form-select {
  appearance: none;
  background-image: $form-select-chevron;
  background-position: top 50% right rem(16);
  background-repeat: no-repeat;
  color: $form-input-color;

  &:has(option[value='']:checked) {
    color: rgba($color-slate, 50%);
  }
}
