@use '../../../styles/utils' as *;

.tile {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.imgWrapper {
  height: rem(180);
  margin-bottom: rem(26);
  width: rem(180);

  > img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.pill {
  margin-bottom: rem(10);
}

.description {
  @include base-paragraph-regular;

  margin-bottom: rem(16);
  margin-top: rem(8);
  text-align: center;

  @include breakpoint-lg {
    margin: rem(16) 0;
  }
}
