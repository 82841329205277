@use '../../../styles/utils' as *;

.mapCardContainer {
  display: flex;
  flex-direction: column;
  padding: 0;
  width: rem(320);
}

.mapCardTitle {
  align-items: center;
  background-color: $color-lime;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: rem(8) rem(10);
}

.orangeBackground {
  background-color: $color-orange !important;
}

.blueBackground {
  background-color: $color-blue !important;
}

.lilacBackground {
  background-color: $color-lilac !important;
}

.mapCardCta button {
  justify-content: center;
  width: 100%;
}

.mapCardHero img {
  height: rem(168);
  object-fit: cover;
  width: 100%;
}

.mapCardCta {
  display: flex;
  padding: rem(15);
}

.mapCardCta span,
.mapCardCta a {
  flex: 1;
  width: auto;
}

/* The popup bubble styling. */
.popupBubble {
  /* Style the bubble. */
  background-color: $color-white;
  box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 50%);
  left: 0;
  overflow-y: auto;
  padding: 0;

  /* Position the bubble centred-above its parent. */
  position: absolute;
  top: 0;
  transform: translate(-50%, -100%);
}

/* The parent of the bubble. A zero-height div at the top of the tip. */
.popupBubbleAnchor {
  bottom: rem(8);
  left: 0;

  /* Position the div a fixed distance above the tip. */
  position: absolute;
  width: 100%;
}

/* This element draws the tip. */
.popupBubbleAnchor::after {
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 12px solid #fff;
  content: '';
  height: 0;
  left: 0;
  position: absolute;
  top: -1px;

  /* Center the tip horizontally. */
  transform: translate(-50%, 0);

  /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
  width: 0;
}

.popupContainer {
  cursor: auto;
  height: 0;
  position: absolute;

  /* The max width of the info window. */
  width: rem(320);
}
