@use '../../styles/utils' as *;

.groupHeading {
  font-weight: 700;
  margin: rem(4) 0 rem(18);
}

.groupList {
  margin-left: rem(24);
}

.groupListItem {
  margin-bottom: rem(16);
}

.columnHeading {
  margin-bottom: rem(36);

  @include breakpoint-xl {
    margin-bottom: rem(48);
  }
}

.lastColumnHeading {
  margin-top: rem(64);

  @include breakpoint-xl {
    margin-top: 0;
  }
}
