@use '../core' as *;

@mixin animated-accordion-button($rotate-degrees) {
  // rotate button when accordion collapsed
  button svg {
    @include transition-linear-fast(transform);
  }

  // rotate button when accordion expanded
  &[aria-expanded='true'] {
    button svg {
      transform: rotate($rotate-degrees);
    }
  }
}

@mixin animated-accordion-panel {
  display: block;

  // animated accordion expand/collapse
  &[aria-hidden='true'] {
    @include transition-collapse(max-height);

    max-height: 0;
    overflow: hidden;

    // focusable elements must remain unfocusable when parent has aria-hidden='true'
    a {
      display: none;
    }
  }

  &[aria-hidden='false'] {
    @include transition-expand(max-height);

    height: auto;
    max-height: rem($breakpoint-xxxl);
    overflow: hidden;
  }
}
