@use '../../../styles/utils' as *;

.mapListingWrapper {
  background-color: $color-light-lime;
  display: flex;
  flex-direction: column;

  @include breakpoint-md {
    box-sizing: border-box;
    flex-direction: row;
    padding-left: rem(24);
  }
}

.mapListingLeft {
  box-sizing: border-box;
  flex-basis: 50%;
  min-width: auto;
  padding: rem(24);

  @include breakpoint-md {
    padding-left: calc(50% - rem(720));
    width: calc(50%);
  }
}

.mapListingHeader {
  padding-bottom: rem(15);
}

.mapListingHeader h1 {
  margin-bottom: rem(75);
}

.infoTextBlock {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.infoTextBlock p {
  font-weight: bold;
}

.mapToggle {
  display: block;

  @include breakpoint-md {
    display: none;
  }
}

.mapGridList {
  display: grid;
  gap: rem(10);
  grid-template-columns: repeat(auto-fill, minmax(rem(285), 1fr));
  justify-items: center;
  row-gap: rem(22);

  @include breakpoint-md {
    gap: rem(22);
  }
}

.mapListPagination {
  display: flex;
  justify-content: center;
  padding: rem(40) 0 rem(16);
}

.mapListingRight {
  background-color: #e5e3df;

  @include breakpoint-md {
    flex: 1;
  }
}

.mapWidget {
  flex-grow: 1;
  height: 100%;
  min-height: 100vh;
}

.mapWidget > div {
  min-height: 100vh;
}

.hidden {
  display: none;

  @include breakpoint-md {
    display: block;
  }
}
