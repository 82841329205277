@use '../../styles/utils' as *;

.accordionBlock {
  align-items: center;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  padding: rem(40) 0;

  @include breakpoint-md {
    padding: rem(64) 0 rem(40);
  }

  @include breakpoint-xl {
    padding: rem(80) 0;
  }

  h1 {
    margin-bottom: rem(16);
    text-align: center;
  }

  div p {
    margin-bottom: rem(16);
    max-width: rem(600);
    text-align: center;
  }
}

.accordion {
  margin-top: rem(24);
  max-width: rem(1200);
  width: 100%;
}

.itemHeading {
  @include base-paragraph-bold;

  border-bottom: rem(1) solid $color-light-slate;

  &:hover {
    background-color: rgba($color-slate, 0.08);
    cursor: pointer;
  }
}

.itemButton {
  @include animated-accordion-button(180deg);

  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: rem(16);

  button {
    flex-shrink: 0;
  }

  &:focus {
    outline: none;
  }
}

.itemPanel {
  @include animated-accordion-panel;
}

.itemContent {
  @include base-paragraph-regular;

  padding: rem(24) rem(16);

  div p {
    max-width: 100%;
    text-align: left;
  }
}
