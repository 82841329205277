@use '../../styles/utils' as *;

.mapWidget {
  display: flex;
  height: 100%;
  width: 100%;
}

.status {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  gap: rem(20);
  justify-content: center;
  padding: rem(20);

  h4 {
    display: flex;
    gap: 10px;

    span {
      width: rem(20);
    }
  }
}
