@use '../../styles/utils' as *;

.discover {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: rem(40) 0;

  @include themify($themes) {
    background-color: themed('backgroundColorSecondary');
  }

  @include breakpoint-md {
    padding: rem(64) 0;
  }

  @include breakpoint-xl {
    padding: rem(80) 0;
  }
}

.discoverBody {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.discoverContent {
  max-width: rem(588);
  text-align: center;

  p {
    @include base-paragraph-regular;

    margin-bottom: rem(32);
    margin-top: rem(16);

    @include breakpoint-xl {
      margin-bottom: rem(40);
    }
  }
}

.cardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @include breakpoint-xl {
    flex-direction: row;
  }

  > div {
    margin-bottom: rem(16);
    margin-right: 0;

    @include breakpoint-xl {
      margin-bottom: 0;
      margin-right: rem(24);
    }

    &:last-child {
      margin-bottom: 0;
      margin-right: 0;
    }
  }
}
