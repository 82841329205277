@use '../../../styles/utils' as *;

.contactUsNationalScrollAnchor {
  @include scrolloffset;
}

.contactUsNationalHeadOffice {
  background-color: $color-light-lime;
  margin-top: rem(40);
  padding: rem(80) 0 rem(40);

  h2 {
    text-align: center;
  }
}

.contactUsNationalMapWrapper {
  height: 400px;
  padding-top: rem(40);
}
