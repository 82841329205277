@use '../../styles/utils' as *;

.headingSlate {
  color: $color-lime;
}

.descriptionSlate {
  color: $color-white;
}

.heroTitle {
  @include text-h1;

  margin-bottom: rem(8);

  @include breakpoint-sm {
    @include font-size(48 56);
  }

  @include breakpoint-xl {
    @include font-size(56 64);
  }
}

.heroDescription {
  @include text-h3;

  margin-bottom: rem(12);

  @include breakpoint-sm {
    @include font-size(18 24);
  }

  @include breakpoint-xl {
    @include font-size(24 32);
  }
}

.buttons {
  padding: rem(3);
}
