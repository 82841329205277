@use '../../styles/utils' as *;

.panelContainer {
  color: $color-slate;
  padding: rem(64) 0;
  text-align: center;

  @include breakpoint-md {
    padding: rem(80) 0;
    text-align: left;
  }

  @include breakpoint-xl {
    padding: rem(120) 0;
  }

  h2 {
    color: $color-blue;
    margin-bottom: rem(24);

    @include breakpoint-md {
      margin-bottom: 0;
    }
  }
}

.ctasContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: rem(16);
  padding-top: rem(32);

  @include breakpoint-md {
    align-items: flex-start;
    flex-direction: row;
    gap: rem(14);
  }

  @include breakpoint-xl {
    gap: rem(24);
  }
}
