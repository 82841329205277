@use '../../styles/utils' as *;

.searchControls {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: rem(8);

  @include breakpoint-lg {
    justify-content: flex-start;
  }

  button:first-child {
    margin-right: rem(8);
  }

  div {
    left: rem(200);
    top: rem(250);
    z-index: 1;

    @include breakpoint-md {
      left: rem(480);
      top: rem(240);
    }

    @include breakpoint-lg {
      left: rem(160);
      top: rem(280);
    }
  }
}

.filters {
  margin-top: rem(16);
  padding: rem(35) 0 rem(32) 0;

  @include breakpoint-lg {
    padding: rem(35) rem(50) rem(32) rem(50);
  }

  svg {
    height: rem(20);
  }
}

.typeFilter {
  align-items: center;
  display: flex;

  p {
    margin-left: rem(10);
  }
}

.filterButtonsWrapper {
  align-items: flex-start;
  border-bottom: 2px solid rgba(50, 55, 55, 8%);
  display: flex;
  flex-direction: column;
  margin-top: rem(16);
  padding-bottom: rem(24);

  @include breakpoint-sm {
    align-items: center;
    flex-direction: row;
    padding-bottom: rem(12);
  }

  @include breakpoint-md {
    align-items: center;
    flex-direction: row;
    padding-bottom: rem(24);
  }

  button {
    margin: 0 0 rem(12) 0;

    @include breakpoint-sm {
      margin: 0 rem(12) 0 0;
    }
  }
}

.priceFilterWrapper {
  border-bottom: 2px solid rgba(50, 55, 55, 8%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: rem(30) 0 rem(20) 0;
}

.priceFilterHeading {
  align-items: center;
  display: flex;

  p {
    margin-left: rem(16);
  }
}

.slider {
  color: $color-slate !important;

  // TODO - this is a bit hacky.
  // We tweak MUI slider markers so they don't overflow the page padding.
  // There might be a better way
  span[class$='MuiSlider-markLabel'] {
    margin: 0 rem(20);
  }

  span[class$='MuiSlider-markLabel']:nth-last-child(3) {
    margin: 0 0 0 rem(-32);
  }
}

.slider:hover {
  color: $color-slate !important;
}

.plusMinusFiltersSectionWrapper {
  border-bottom: 2px solid rgba(50, 55, 55, 8%);
  padding-top: rem(30);
}

.plusMinusFilterWrapper {
  align-items: flex-start;
  display: column;
}

.plusMinusFilterHeading {
  align-items: center;
  display: flex;

  p {
    margin-left: rem(10);
  }
}

.bottomButtonsWrapper {
  align-items: center;
  display: flex;
  margin-top: rem(30);

  @include breakpoint-sm {
    justify-content: flex-end;
  }

  button {
    width: 50%;

    @include breakpoint-sm {
      width: auto;
    }

    &:last-child {
      margin-left: rem(10);
    }
  }
}
