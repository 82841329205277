@use '../../styles/utils' as *;

.sectionTitle {
  padding: rem(120) 0;
  text-align: center;
}

.textLeft {
  text-align: left;
}

.description {
  @include base-large-regular;

  margin-top: rem(16);
  text-align: center;
}
