@use '../../styles/utils' as *;

.accordion {
  @include font-size(16 24);

  color: $color-slate;
  letter-spacing: -0.01em;
  width: 100%;
}

.accordionButton {
  @include animated-accordion-button(90deg);

  align-items: center;
  border-top: 2px solid $color-light-slate;
  display: flex;
  justify-content: space-between;
  padding: rem(12) 0 rem(14);

  button {
    &:hover,
    &:focus {
      background-color: $color-slate;
      border-color: $color-white;
      fill: $color-white;
    }
  }
}

.stateHeading {
  align-items: center;
  border-top: 2px solid $color-light-slate;
  display: flex;
  justify-content: space-between;
  padding: rem(64) rem(24) rem(14) rem(24);

  h3 {
    margin-right: rem(14);
  }

  button {
    &:hover,
    &:focus {
      background-color: $color-slate;
      border-color: $color-white;
      fill: $color-white;
    }
  }
}

.stateHeadingFirst {
  border-top: 0 none;
  padding: rem(12) rem(24) rem(14) rem(24);
}

.communityListItem {
  @include transition-linear(background-color);

  align-items: center;
  border-top: 2px solid $color-light-slate;
  display: flex;
  justify-content: space-between;
  padding: rem(12) 0 rem(14);

  @include breakpoint-lg {
    padding: rem(12) rem(24) rem(14) rem(24);
  }

  button {
    &:hover,
    &:focus {
      background-color: $color-slate;
      border-color: $color-white;
      fill: $color-white;
    }
  }

  svg {
    height: rem(12);
    width: rem(8);
  }

  p {
    line-height: rem(24);
  }

  &:hover {
    background-color: rgba($color-slate, 8%);
    cursor: pointer;
  }
}

.accordionFirstButton {
  border-top: 0 none;
}

.accordionItemSubheading {
  color: $color-slate;
  font-size: rem(14);
  opacity: 0.5;
}

.accordionItemPanel {
  @include animated-accordion-panel;
}
