@use '../../styles/utils' as *;

.propertySearchContainer {
  margin-bottom: rem(40);
}

.propertyGrid {
  display: grid;
  grid-row-gap: rem(40);
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 1fr;
  padding: rem(32) 0;

  @include breakpoint-md {
    grid-column-gap: rem(24);
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
  }

  @include breakpoint-lg {
    grid-template-columns: repeat(4, 1fr);
  }
}

.loadingState {
  display: flex;
  justify-content: center;
  padding: 0 0 rem(40) 0;
  text-align: center;
}

.paginationWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: rem(16);
  width: 100%;
}

.searchResultsMessage {
  margin-top: rem(14);
}

.noResultsMessage {
  margin-top: rem(112);
  text-align: center;
}
