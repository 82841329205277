@use '../../styles/utils' as *;

.salesBannerBlock {
  background-color: $color-lime;
  padding: rem(40) 0;

  @include breakpoint-sm {
    padding: rem(64) 0;
  }

  @include breakpoint-xl {
    padding: rem(80) 0;
  }

  h2 {
    margin-bottom: rem(16);
    text-align: center;

    @include breakpoint-sm {
      text-align: left;
    }
  }
}

.copy,
.cta {
  display: flex;
  gap: rem(16);
  justify-content: center;
  margin-bottom: rem(24);
  text-align: center;

  @include breakpoint-sm {
    justify-content: flex-start;
    text-align: left;
  }

  @include breakpoint-md {
    align-items: flex-start;
    flex-direction: row;
    gap: rem(14);
  }

  @include breakpoint-xl {
    gap: rem(24);
  }

  blockquote {
    background-color: $color-light-lime;
    margin-bottom: rem(24);
    padding: rem(24);

    hr {
      display: none;
    }

    p {
      @include base-large-semibold;

      font-family: $body-font-family;
      margin: 0;
      padding: 0;
    }
  }
}

.disclaimer p {
  @include base-smallest-regular;

  text-align: center;

  @include breakpoint-sm {
    text-align: left;
  }
}
