@use '../core' as *;

@mixin element-color-classes($element, $prop) {
  .colorBlack {
    #{$element} {
      #{$prop}: $color-black;
    }
  }

  .colorWhite {
    #{$element} {
      #{$prop}: $color-white;
    }
  }

  .colorPrimary {
    @include themify($themes) {
      #{$element} {
        #{$prop}: themed('backgroundColor');
      }
    }
  }

  .colorSecondary {
    @include themify($themes) {
      #{$element} {
        #{$prop}: themed('backgroundColorSecondary');
      }
    }
  }

  .colorSlate {
    #{$element} {
      #{$prop}: $color-slate;
    }
  }

  .colorLightSlate {
    #{$element} {
      #{$prop}: $color-light-slate;
    }
  }

  .colorLime {
    #{$element} {
      #{$prop}: $color-lime;
    }
  }

  .colorLightLime {
    #{$element} {
      #{$prop}: $color-light-lime;
    }
  }

  .colorYellow {
    #{$element} {
      #{$prop}: $color-yellow;
    }
  }

  .colorLightYellow {
    #{$element} {
      #{$prop}: $color-light-yellow;
    }
  }

  .colorOrange {
    #{$element} {
      #{$prop}: $color-orange;
    }
  }

  .colorLightOrange {
    #{$element} {
      #{$prop}: $color-light-orange;
    }
  }

  .colorLilac {
    #{$element} {
      #{$prop}: $color-lilac;
    }
  }

  .colorLightLilac {
    #{$element} {
      #{$prop}: $color-light-lilac;
    }
  }

  .colorBlue {
    #{$element} {
      #{$prop}: $color-blue;
    }
  }

  .colorLightBlue {
    #{$element} {
      #{$prop}: $color-light-blue;
    }
  }

  .colorError {
    #{$element} {
      #{$prop}: $color-error;
    }
  }
}

@mixin color-classes($prop) {
  .colorBlack {
    #{$prop}: $color-black;
  }

  .colorWhite {
    #{$prop}: $color-white;
  }

  .colorPrimary {
    @include themify($themes) {
      #{$prop}: themed('backgroundColor');
    }
  }

  .colorSecondary {
    @include themify($themes) {
      #{$prop}: themed('backgroundColorSecondary');
    }
  }

  .colorSlate {
    #{$prop}: $color-slate;
  }

  .colorLightSlate {
    #{$prop}: $color-light-slate;
  }

  .colorLime {
    #{$prop}: $color-lime;
  }

  .colorLightLime {
    #{$prop}: $color-light-lime;
  }

  .colorYellow {
    #{$prop}: $color-yellow;
  }

  .colorLightYellow {
    #{$prop}: $color-light-yellow;
  }

  .colorOrange {
    #{$prop}: $color-orange;
  }

  .colorLightOrange {
    #{$prop}: $color-light-orange;
  }

  .colorLilac {
    #{$prop}: $color-lilac;
  }

  .colorLightLilac {
    #{$prop}: $color-light-lilac;
  }

  .colorBlue {
    #{$prop}: $color-blue;
  }

  .colorLightBlue {
    #{$prop}: $color-light-blue;
  }

  .colorError {
    #{$prop}: $color-error;
  }
}
