@use '../../styles/utils' as *;

.productPromotionBlock {
  align-items: center;
  background-color: $color-slate;
  color: $color-white;
  display: flex;
  justify-content: center;
  padding: rem(40) 0;

  @include breakpoint-md {
    padding: rem(64) 0;
  }

  @include breakpoint-xl {
    padding: rem(80) 0;
  }
}

.productPromotionBlockBody {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.productPromotionBlockContent {
  margin-bottom: rem(40);
  max-width: rem(588);
  text-align: center;

  @include breakpoint-xl {
    margin-bottom: rem(64);
  }
}

.description {
  @include base-paragraph-regular;
  @include font-size(18 26);

  font-weight: 400;
  margin-top: rem(8);

  @include breakpoint-md {
    margin-top: rem(12);
  }

  @include breakpoint-xl {
    margin-top: rem(16);
  }
}

.cardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @include breakpoint-xl {
    flex-direction: row;
  }

  > div {
    margin-bottom: rem(16);
    margin-right: 0;

    @include breakpoint-xl {
      margin-bottom: 0;
      margin-right: rem(24);
    }

    &:last-child {
      margin-bottom: 0;
      margin-right: 0;
    }
  }
}
