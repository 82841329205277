@use '../../styles/utils' as *;

$triangle-md: 305px;
$triangle-xl: 408px;

.alternatingTextBlock {
  min-height: rem(320);
  overflow: hidden;
  position: relative;

  @include breakpoint-md {
    margin-bottom: rem(40);
  }

  @include breakpoint-xl {
    margin-bottom: rem(64);
  }
}

// Large Layout for Tablet and Desktop
.alternatingTextBlockLarge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;

  @include breakpoint-md {
    height: rem(600);
    width: $triangle-md;
  }

  @include breakpoint-xl {
    width: $triangle-xl;
  }

  &Right {
    float: right;
  }
}

.background {
  height: 100%;
  position: absolute;
  width: rem($breakpoint-xl);

  @include breakpoint-md {
    left: 0;
  }

  &Right {
    left: unset;
    right: 0;
    transform: scaleX(-1);
  }
}

.backgroundBlock {
  background-color: $color-light-lime;
  height: 100%;
  width: 0;

  @include themify($themes) {
    background-color: themed('backgroundColorSecondary');
  }

  @include breakpoint-md {
    width: calc($triangle-md);
  }

  @include breakpoint-xl {
    width: calc($triangle-xl);
  }
}

.backgroundTriangle {
  bottom: 0;
  fill: $color-light-lime;
  height: auto;
  position: absolute;
  width: 100%;

  @include themify($themes) {
    fill: themed('backgroundColorSecondary');
  }

  @include breakpoint-md {
    bottom: 0;
    height: 100%;
    left: calc($triangle-md - 1px);
    position: absolute;
    transform: none;
    width: initial;
  }

  @include breakpoint-xl {
    left: calc($triangle-xl - 1px);
  }
}

.backgroundMediaWrapper {
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;

  @include breakpoint-md {
    div[data-next-image] {
      height: 100%;
      left: $triangle-md;
      position: absolute;
      width: 100%;
    }
  }

  @include breakpoint-xl {
    div[data-next-image] {
      left: $triangle-xl;
      position: absolute;
    }
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.backgroundMediaWrapperRight {
  @include breakpoint-md {
    div[data-next-image] {
      left: unset;
      right: $triangle-md;
    }
  }

  @include breakpoint-xl {
    div[data-next-image] {
      right: $triangle-xl;
    }
  }
}

.ctaWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: rem(24);

  > span {
    margin-bottom: rem(12);
    margin-right: rem(24);

    a {
      @include themify($themes) {
        background-color: themed('backgroundColorQuaternary');
      }
    }

    a:hover {
      background-color: $color-slate;
    }
  }
}

.contentWrapper {
  padding: 0 rem(24) 0 rem(56);

  &Right {
    padding: 0 rem(56) 0 rem(24);
  }
}

.mobileContentWrapper {
  margin-bottom: rem(24);
  margin-top: rem(-1);
  padding: 0 rem(24) rem(24) rem(24);

  @include themify($themes) {
    background-color: themed('backgroundColorSecondary');
  }
}

.heading {
  @include text-h2;

  margin-bottom: rem(24);
}

.buttonContainer {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100vw;
  z-index: 0;

  @include breakpoint-md {
    left: $triangle-md;
    right: 0;
    top: 0;
    width: unset;
  }

  @include breakpoint-lg {
    left: $triangle-xl;
    right: 0;
  }

  &Left {
    @include breakpoint-md {
      left: 0;
      right: $triangle-md;
    }

    @include breakpoint-lg {
      left: 0;
      right: $triangle-xl;
    }
  }
}

.playButton {
  align-items: center;
  background-color: $color-lime;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: rem(100);
  justify-content: center;
  position: absolute;
  width: rem(100);
  z-index: 0;
}

.playIcon {
  margin-left: 6px;
  width: rem(36);
}
