@use '../../styles/utils' as *;

.alternatingGalleryBlock {
  display: flex;
  flex-flow: column wrap;
  padding: rem(40) 0;

  @include breakpoint-md {
    padding: rem(64) 0;
  }

  @include breakpoint-xl {
    padding: rem(80) 0;
  }
}

.heading {
  margin-bottom: rem(16);
}

.richTextWrapper {
  margin-bottom: rem(24);
}

.ctaWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: rem(24) 0;

  > span {
    margin-bottom: rem(12);
    margin-right: rem(24);
  }
}

.galleryShowcase {
  display: flex;
  flex-direction: column;

  @include breakpoint-xl {
    flex-direction: row;
  }
}

.featuredImage {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: rem(194);
  justify-content: center;
  overflow: hidden;
  width: 100%;

  @include breakpoint-md {
    height: rem(436);
  }
}

.snippets {
  display: grid;
  gap: rem(2);
  grid-template: 1fr / 1fr 1fr 1fr;
  margin-left: rem(0);
  margin-top: rem(2);

  @include breakpoint-xl {
    grid-template: 1fr 1fr 1fr / 1fr;
    margin-left: rem(2);
    margin-top: 0;
    width: fit-content;
  }

  img {
    aspect-ratio: 1/1;
  }
}

.snippetSquare {
  cursor: pointer;
  height: rem(88);
  width: inherit;

  @include themify($themes) {
    background-color: themed('backgroundColor');
  }

  @include breakpoint-md {
    height: rem(144);
  }

  @include breakpoint-xl {
    aspect-ratio: 1/1;
  }
}

.snippetViewAll {
  align-items: center;
  display: flex;
  justify-content: center;
}

.galleryOverlay {
  align-items: center;
  background-color: $color-slate;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999999;
}

.galleryContent {
  background: none;
  border: 0;
  border-radius: 0;
  height: 100%;
  inset: 0;
  overflow: hidden;
  padding: 0;
  position: unset;
  width: 100%;
}
