@use '../../../styles/utils' as *;

.productPromotionCard {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  position: relative;
  width: inherit;

  @include breakpoint-md {
    flex-direction: row;
  }

  @include breakpoint-xl {
    flex-direction: column;
    max-width: rem(587);
  }
}

.backgroundWrapper {
  min-height: rem(200);
  overflow: hidden;
  position: relative;
  z-index: 0;

  @include breakpoint-md {
    min-height: 0;
    width: 50%;
  }

  @include breakpoint-xl {
    max-width: rem(587);
    min-height: rem(300);
    width: initial;
  }
}

.backgroundMediaWrapper {
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;

  @include breakpoint-md {
    width: 100%;

    img {
      height: 100%;
      left: 0;
      width: 100%;
    }
  }

  @include breakpoint-xl {
    height: rem(300);
    width: 100%;
  }

  img {
    height: 100%;
    object-fit: cover;
    object-position: 0% 50%;
    position: absolute;
    width: 100%;
    z-index: -100;
  }
}

.background {
  height: 100%;
}

.backgroundBlock {
  background-color: $color-lime;
  height: 100%;
  position: absolute;
  right: 0;
  width: calc(5% + 1px);

  @include breakpoint-md {
    display: none;
  }

  @include themify($themes) {
    background-color: themed('backgroundColorTertiary');
  }
}

.backgroundTriangle {
  fill: $color-lime;
  position: absolute;
  right: 5%;

  @include breakpoint-md {
    right: rem(-16);
  }

  @include breakpoint-xl {
    right: rem(-16);
  }

  @include themify($themes) {
    fill: themed('backgroundColorTertiary');
  }
}

.headingWrapper {
  background-color: $color-white;
  color: $color-slate;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: auto;
  justify-content: space-between;
  padding: rem(26) rem(32) rem(32);

  @include breakpoint-md {
    width: 50%;
  }

  @include breakpoint-xl {
    width: initial;
  }
}

.option {
  margin-bottom: rem(8);
}

.description {
  @include base-paragraph-regular;

  margin-bottom: rem(24);
  margin-top: rem(8);
}

.cta {
  width: fit-content;
}

.yellowBlock {
  background-color: $color-yellow !important;
}

.orangeBlock {
  background-color: $color-orange !important;
}

.blueBlock {
  background-color: $color-blue !important;
}

.yellowTriangle {
  fill: $color-yellow !important;
}

.orangeTriangle {
  fill: $color-orange !important;
}

.blueTriangle {
  fill: $color-blue !important;
}
