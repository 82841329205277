@use '../../styles/utils' as *;

.mediaImageBlock {
  aspect-ratio: 16/9;
  padding-top: rem(40);
  position: relative;
  display: flex;
  margin: 0 auto rem(40);

  @include breakpoint-md {
    margin-bottom: rem(64);
  }

  @include breakpoint-xl {
    margin-bottom: rem(80);
  }
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.mediaImageBlockWrapper {
  margin-bottom: rem(40);
  width: 100%;

  @include breakpoint-xl {
    width: rem(800);
  }

  @include breakpoint-md {
    margin-bottom: rem(80);
  }
}
