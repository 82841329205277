@use '../../styles/utils' as *;

.dropdown {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-position: right 0.6rem center;
  background-repeat: no-repeat;
  background-size: 1em;
  border-radius: rem(32);
  border-width: 0;
  height: rem(44);
  padding: rem(8) rem(24);

  @include themify($themes) {
    background-color: themed('backgroundColorTertiary');
  }
}

// TODO - this is a wip version that is more aligned with designs,
// but introduces accessibility and mobile UX challenges.
// We have agreed to start with a native select box version
// (see above), keeping the below code here in case we do need to swap
// to a more customised dropdown
.dropdownV2 {
  filter: drop-shadow(0 4px 24px rgba(0, 0, 0, 25%));
  position: absolute;

  li {
    @include transition-linear(background-color);

    background-color: $color-white;
    cursor: pointer;
    padding: rem(12) rem(16);

    &:hover {
      background-color: rgba(239, 239, 239);
    }
  }
}
