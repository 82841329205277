@use '../../styles/utils' as *;

.introText {
  padding-bottom: rem(40);
  text-align: center;

  @include themify($themes) {
    color: themed('textColorTertiary');
  }

  @include breakpoint-md {
    padding-bottom: rem(64);
  }

  @include breakpoint-xl {
    padding-bottom: rem(80);
  }
}
