@use '../../styles/utils' as *;

.videoBlock {
  margin-bottom: rem(40);

  @include breakpoint-md {
    margin-bottom: rem(64);
  }

  @include breakpoint-xl {
    margin-bottom: rem(80);
  }

  &Themed {
    @include themify($themes) {
      background-color: themed('backgroundColorSecondary');
    }
  }

  @include themify($themes) {
    color: themed('textColorTertiary');
  }
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.textContainer {
  text-align: center;
  width: auto;

  @include breakpoint-xl {
    width: rem(588);
  }
}

.heading {
  margin-bottom: rem(16);
}

.description {
  margin-bottom: rem(40);
}

.videoBlockWrapper {
  margin-bottom: rem(40);
  width: 100%;

  @include breakpoint-xl {
    width: rem(800);
  }
}
